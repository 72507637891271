import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Button, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "180px 0 180px 0",
	"background": "linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://optirealpro.com/img/3.jpg) 50% 50% /cover repeat scroll padding-box",
	"md-padding": "100px 0 100px 0",
	"quarkly-title": "Content-12"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "0px 80px 0px 0px",
			"width": "50%",
			"lg-width": "70%",
			"md-width": "100%",
			"md-padding": "0px 0 0px 0px",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "white",
			"children": "Rem's Barber Studio"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline2",
			"color": "white",
			"children": "Повысьте свой уровень ухода за собой"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--light",
			"font": "--base",
			"children": "Откройте для себя разницу между Rem's Barber уже сегодня. Запишитесь на прием прямо сейчас и почувствуйте себя лучшим парикмахером. Ваш идеальный образ ждет вас!"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"href": "/contact",
			"type": "link",
			"text-decoration-line": "initial",
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"background": "--color-dark",
			"padding": "12px 28px 12px 28px"
		}
	}
};

const New = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			align-items="center"
			justify-content="center"
			md-flex-direction="column"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Text {...override("text2")} />
			<Button {...override("button")}>
			Контакты
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(New, { ...Section,
	defaultProps,
	overrides
});
export default New;